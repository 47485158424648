import request from '@/plugins/axios'

/** 区域代理操作 **/

export const apiRegionList = (params: any) => request.get('/region.region/regionList', { params })

export const apiRegionAgentTypeList = () => request.get('/region_agent.region_agent/typeList')

export const apiRegionAgentAdd = (data: any) => request.post('/region_agent.region_agent/add', data)

export const apiRegionAgentList = (params: any) => request.get('/region_agent.region_agent/lists', { params })

export const apiRegionAgentUpdate = (params: any) => request.get('/region_agent.region_agent/update', { params })

export const apiRegionAgentInfo = (params: any) => request.get('/region_agent.region_agent/info', { params })

export const apiRegionAgentEdit = (data: any) => request.post('/region_agent.region_agent/edit', data)

export const apiRegionAgentCommissionList = (params: any) => request.get('/region_agent.commission/lists', { params })

export const apiRegionAgentWithdrawInfo = (params: any) => request.get('/region_agent.region_agent_withdraw/info', { params })

export const apiRegionAgentWithdraw = (data: any) => request.post('/region_agent.region_agent_withdraw/withdraw', data)

export const apiRegionAgentWithdrawList = (params: any) => request.get('/region_agent.region_agent_withdraw/lists', { params })
